import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Reveal } from 'react-genie'
import { customMedia } from '../common/customMedia'
import { Member as MemberModel } from '../../models/member'
import { parse } from 'querystring'
import { Image } from '../common/Image'
import { useTranslation, Trans } from 'react-i18next'

export interface Props {
  memberList: MemberModel[]
  advisoryBoardList: MemberModel[]
  isListLoading: boolean
}

export interface Handlers {
  fetchList: (draftKey: string) => void
}

export const Member = (props: Props & Handlers) => {
  const { memberList, advisoryBoardList } = props
  const { fetchList } = props

  const [t, i18n] = useTranslation()

  useEffect(() => {
    const query = parse(window.location.search.slice(1))
    fetchList(query.draftKey as string)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      const hash = window.location.hash.slice(1)
      const element = document.getElementById(hash)

      window.scrollTo({
        behavior: element ? 'smooth' : 'auto',
        top: element ? element.offsetTop - 80 : 0
      });
    }, 100);
  }, []);

  return (
    <>
      <Container>
        <Wrapper>
          <MemberWrapper>
            <Reveal delay={500}>
              <MemberIconWrapper>
                <Image path="member@2x.png" />
              </MemberIconWrapper>
            </Reveal>

            <MemberListWrapper>
              {memberList.map((m, i) => (
                <MemberCardWrapper key={i} id={m.id}>
                  <Reveal delay={500}>
                    <MemberCard>
                      <ImgWrapper>
                        <Img src={m.image.url} alt={m.name} />
                      </ImgWrapper>
                      <ContentWrapper>
                        <NameWrapper>
                          <Name>{i18n.language === 'ja' ? m.name : m.enName}</Name>
                          <EnName>{i18n.language === 'ja' && m.enName}</EnName>
                        </NameWrapper>
                        <Title>{i18n.language === 'ja' ? m.title : m.enTitle}</Title>
                        <Description>{i18n.language === 'ja' ? m.description : m.enDescription}</Description>
                      </ContentWrapper>
                    </MemberCard>
                  </Reveal>
                </MemberCardWrapper>
              ))}
            </MemberListWrapper>      
          </MemberWrapper>
      
          <MemberWrapper>
            <AdvisoryBoardIconWrapper>
              <Image path="advisory_board@2x.png" />
            </AdvisoryBoardIconWrapper>

            <MemberListWrapper>
              {advisoryBoardList.map((m, i) => (
                <MemberCardWrapper key={i} id={m.id}>
                  <Reveal delay={500}>
                    <MemberCard>
                      <ImgWrapper>
                        <Img src={m.image.url} alt={m.name} />
                      </ImgWrapper>
                      <ContentWrapper>
                        <NameWrapper>
                          <Name>{i18n.language === 'ja' ? m.name : m.enName}</Name>
                          <EnName>{i18n.language === 'ja' && m.enName}</EnName>
                        </NameWrapper>
                        <Title>{(i18n.language === 'ja' ? m.title : m.enTitle).split('\n').map((m, i) => <span key={i}>{m}<br/></span>)}</Title>
                        <Description>{i18n.language === 'ja' ? m.description : m.enDescription}</Description>
                      </ContentWrapper>
                    </MemberCard>
                  </Reveal>
                </MemberCardWrapper>
              ))}
            </MemberListWrapper>
          </MemberWrapper>
        </Wrapper>
      </Container>
    </>
  )
}

const Container = styled.div`
  ${customMedia.lessThan("medium")`
    padding: 75px 20px;
  `}
  ${customMedia.greaterThan("medium")`
    padding: 120px 0;
  `}
  ${customMedia.greaterThan("large")`
    padding: 150px 0;
  `}

  background-color: #efefef;
`

const Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    margin: 0 auto;
    width: 735px;
  `}
  ${customMedia.greaterThan("large")`
    margin: 0 auto;
    width: 1000px;
  `}
`

const MemberWrapper = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const MemberIconWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-bottom: 30px;
    width: 80px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-bottom: 70px;
    width: 160px;
  `}

  margin: 0 auto;
`

const AdvisoryBoardIconWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 30px auto 50px;
    width: 150px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 60px auto;
    width: 300px;
  `}

`

const MemberListWrapper = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const MemberCardWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-bottom: 55px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-bottom: 60px;
  `}
`

const MemberCard = styled.div`
  ${customMedia.lessThan("medium")`
    
  `}
  ${customMedia.greaterThan("medium")`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    margin: 0 auto;
  `}
`

const ImgWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 0 auto;
    width: 130px;
  `}
  ${customMedia.greaterThan("medium")`
    width: 200px;
  `}

  flex: none;
`

const Img = styled.img`
`

const ContentWrapper = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    margin-left: 45px;
  `}
`

const NameWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    text-align: center;
  `}
  ${customMedia.greaterThan("medium")`
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
  `}
`

const Name = styled.h1`
  ${customMedia.lessThan("medium")`
    font-size: 1.5rem;
    margin-top: 10px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 2.4rem;
    margin-right: 40px;
  `}

  color: #26d2ad;
  font-weight: bold;
`

const EnName = styled.h1`
  ${customMedia.lessThan("medium")`
    font-size: 1.2rem;
    margin-bottom: 5px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.8rem;
  `}

  color: #60656b;
`

const Title = styled.h2`
  ${customMedia.lessThan("medium")`
    font-size: 1.15rem;
    text-align: center;
    margin-bottom: 15px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.15rem;
    font-size: 1.7rem;
    margin-bottom: 30px;
  `}

  color: #60656b;
  font-weight: bold;
`

const Description = styled.h3`
  ${customMedia.lessThan("medium")`
    font-size: 1.15rem;
    line-height: 16.5px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.8rem;
    line-height: 30px;
  `}
  color: #60656b;
`
import actions from './actions'
import { getMemberList } from '../../lib/httpService/api'

export const fetchList = (draftKey?: string) => async (dispatch: any) => {
  dispatch(actions.fetchListRequest)

  try {
    const list = await getMemberList({ draftKey })
    dispatch(actions.fetchListSuccess(list))
  } catch (err) {
    console.log(err)
    dispatch(actions.fetchListFailure)
  }
}
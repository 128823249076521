import React from 'react'
import styled from 'styled-components'

import { Layout } from '../components/Layout'
import { SEO } from '../components/common/SEO'

import { Member } from '../components/Member'

const IndexPage = () => (
  <Layout>
    <SEO
      title='遠隔医療で健幸へ導くリモハブのスタッフ'
      description='Remohab（リモハブ）のスタッフ紹介です。大阪大学医学部卒業後、循環器内科医として臨床に従事し、心不全の病態や予後予測に関する研究を行う、創業者・CEOの谷口達典をはじめ、世界一の在宅疾病管理プラットフォーム創りを担う一人一人が、皆様の健幸を願っています。'
      keywords={['リハビリ','遠隔診療','メンバー','スタッフ','リモハブ','remohab']}
    />
    <H1>リハビリ　遠隔診療　メンバー　スタッフ　開発 | Remohab リモハブ</H1>
    <Member />
  </Layout>
)

const H1 = styled.h1`
  font-size: 1.8rem;
`

export default IndexPage

import { createSelector } from 'reselect'
import { Member } from '../../models/member'
import { RootState } from '../types'
import { State } from './types'

const memberSelector = (state: RootState): State => state.member

export const getList = createSelector(
  memberSelector,
  (state: State): Member[] => state.list
)

export const getIsListLoading = createSelector(
  memberSelector,
  (state: State): boolean => state.isListLoading
)

export const getMember = (id: string) =>
  createSelector(
    memberSelector,
    (state: State): Member => state.list.find(p => p.id === id)!
  )

export const getMemberList = createSelector(
  memberSelector,
  (state: State): Member[] => state.list.filter(m => m.category.title === 'Member')
)

export const getAdvisoryBoardList = (limit: number = 0) => createSelector(
  memberSelector,
  (state: State): Member[] => {
    return limit > 0 ? 
      state.list.filter(m => m.category.title === 'Advisory Board').slice(0, limit)
      : state.list.filter(m => m.category.title === 'Advisory Board')
  }
)

export const selectors = {
  getList,
  getIsListLoading,
  getMember,
  getMemberList,
  getAdvisoryBoardList
}
